<style>
.modal-header.rtl button {
  margin: -1rem auto -1rem -1rem;
}

.noSlider {
  margin-top: 40px;
}

.myHeader .owl-stage-outer {
  border-radius: 10px;
}

.orderSection {
  width: 100px;
  height: 50px;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: -30px;
  z-index: 1000;
}

.drtl {
  text-align: right !important;
}
</style>
<template>
  <div>
    <main class="myMain">
      <!-- Modal -->
      <div
        style="z-index: 1000000"
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        :style="isIpad ? 'width: 500px !important; overflow-y: hidden' : ''"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div
              class="modal-header"
              :class="
                $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'
              "
            >
              <h5
                class="modal-title"
                id="exampleModalLabel"
                v-if="modalData && modalData['name' + getLang()]"
              >
                {{ modalData["name" + getLang()] }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="padding: 0 !important">
              <img
                v-if="modalData"
                style="
                  width: 80%;
                  transform: translate(13%, 6%);
                  border-radius: 8px;
                  box-shadow: 0 0 12px rgb(0 0 0 / 30%);
                "
                :src="$hostname + modalData.imageLink.replaceAll('\\', '/')"
                alt=""
              />
            </div>
            <div class="modal-footer d-block text-left px-4 pt-4">
              <h6
                :class="
                  $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'
                "
                v-if="modalData && modalData['description' + getLang()]"
              >
                {{ modalData["description" + getLang()] }}
              </h6>
              <hr style="border-color: #e5cba4" />
              <!-- <p style="font-size: 10px">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p> -->
              <div
                :class="
                  $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'
                "
                class="desc"
                v-if="modalData"
              >
                <strong :style="'color:' + itemPriceColor"
                  >{{ $t("price") }}: {{ modalData.price }}
                  {{ $t("currency") }}</strong
                >
              </div>
              <div v-if="modalData && modalData.itemAddOns">
                <div
                :class="
                  $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'
                "
                  class="addOns d-flex justify-content-between"
                  v-for="a in modalData.itemAddOns"
                  :key="'add' + a.id"
                >
                  <label for="flexCheckDefault"
                    >{{ a["name" + getLang()] }}( +{{ a.price }} )</label
                  >
                  <input
                    @change="selectAddOn(modalData.id, a)"
                    class="form-check-input myInputCheck"
                    type="checkbox"
                    :checked="a.checked"
                    id="flexCheckDefault"
                  />
                </div>
              </div>

              <!-- <div class="form-check">
               
                
                
                <label class="form-check-label" for="flexCheckDefault">
                  (+1000 Extra Sause)
                
              </div> -->

              <!-- <div class="d-flex justify-content-center mt-2">
                <h6
                  :style="
                    !routeTableNumber && !modalData.isDelivery
                      ? ''
                      : 'display:none'
                  "
                  :class="
                    $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'
                  "
                  v-if="modalData"
                  class="text-danger"
                >
                  * {{ $t("notDelivery") }}
                </h6>

                <div
                  :class="
                    !routeTableNumber
                      ? !modalData.isDelivery
                        ? 'd-none'
                        : ''
                      : ''
                  "
                  v-if="modalData"
                >
                  <button
                    data-dismiss="modal"
                    :class="
                      $i18n.locale == 'ku' || $i18n.locale == 'ar'
                        ? 'kurd'
                        : 'en'
                    "
                    class="btn"
                    :style="
                      'background-color:' +
                      btnColor +
                      '; color: ' +
                      btnForeColor
                    "
                    v-if="modalData"
                    @click="addToCart(modalData)"
                  >
                    <i
                      :style="'color: ' + btnForeColor"
                      class="fa fa-shopping-basket"
                    ></i>
                    &nbsp;
                   <span
                      :style="'color: ' + btnForeColor"
                      v-if="getMyQuantity(modalData.id) >= 0"
                      >{{ $t("added") }}</span
                    > 

                    <span :style="'color: ' + btnForeColor">{{
                      $t("addToCart")
                    }}</span>
                    &nbsp;
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- This is the Slider -->

      <div
        class="container margin_30_60 myHeader"
        v-if="showSlider"
        style="
          padding-bottom: 0px !important;
          margin-top: 170px;
          border-radius: 10px;
        "
      >
        <carousel
          style="z-index: 1000000000"
          v-if="sliderItems"
          :nav="false"
          :autoplay="true"
          :responsive="{
            0: { items: 1 },
            600: { items: 1 },
            900: { items: 1 },
          }"
        >
          <img
            v-for="i in sliderItems"
            :key="i.id"
            :src="$hostname + i.imageLink"
            style="width: 100%; height: auto; border-radius: 10px"
          />
        </carousel>
      </div>

      <!-- This is the Categories -->
      <div
        class="container"
        style="padding-bottom: 10px !important"
        :class="!showSlider ? 'noSlider' : ''"
        v-if="Dads"
      >
        <div class="main_title center">
          <br />
          <h2
            :style="'color: ' + mainForeColor"
            :class="
              $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : 'eng'
            "
          >
            {{ $t("pickCat") }}
          </h2>
          <br />
        </div>
        <!-- /main_title -->
        <carousel
          :nav="false"
          :responsive="{
            0: { items: 3 },
            600: { items: 3 },
            900: { items: 5 },
          }"
        >
          <!-- <div class="item_version_2" style="text-align: center">
            <a @click="filterSubCategories(0)">
              <h3>{{$t("All")}}</h3>
            </a>
          </div> -->

          <div
            class="item_version_2"
            style="text-align: center"
            v-for="d in Dads"
            :key="d.id"
          >
            <a
              @click="
                filterSubCategories(d.id);
                selectDadIndex(d.id);
              "
              :class="'dad-' + d.id"
            >
              <h3
                :style="'color: ' + mainForeColor"
                :class="
                  $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : 'eng'
                "
                v-if="d && d['name' + getLang()]"
              >
                {{ d["name" + getLang()] }}
              </h3>
            </a>
          </div>

          <!-- <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3 class="subactiveCata">Drinks</h3>
            </a>
          </div>
          <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3>Hookah</h3>
            </a>
          </div>
          <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3>Cake</h3>
            </a>
          </div>
          <div class="item_version_2" style="text-align: center">
            <a href="grid-listing-filterscol.html">
              <h3>Salad</h3>
            </a>
          </div> -->
        </carousel>
      </div>

      <!-- This is the Sub Categories -->
      <div
        class="container margin_30_60"
        style="padding-bottom: 0; margin-top: -30px"
        v-if="filteredSubCategories"
      >
        <div class="main_title center">
          <br />
          <h2
            :style="'color: ' + mainForeColor"
            :class="
              $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'kurd' : 'eng'
            "
          >
            {{ $t("picSub") }}
          </h2>
          <br />
          <br />
        </div>
        <!-- /main_title -->
        <carousel
          :key="subCategoryKey"
          :nav="false"
          :responsive="{
            0: { items: 4 },
            600: { items: 4 },
            900: { items: 5 },
          }"
        >
          <div
            class="item_version_2"
            style="text-align: center;margin-rigth:5px"
            v-for="i in filteredSubCategories"
            :key="i.id"
            @click="
              filterItem(i.id, i['name' + getLang()]);
              selectSubCategoryIndex(i.id);
            "
          >
            <a
              style="padding: 5px 0"
              @click="filterItem(i.id, i['name' + getLang()])"
            >
              <img
                :class="'subCat-' + i.id"
                class=""
                style="
                  margin: auto;
                  width: 70px;
                  height: 70px;
                  border-radius: 50%;
                  background-color: white;
                "
                :src="$hostname + i.iconLink"
                :data-src="$hostname + i.iconLink"
                alt=""
                width="350"
                height="450"
              />

              <!-- <h3>Pizza</h3> -->
            </a>

            <span
              :style="'color: ' + mainForeColor"
              :class="
                $i18n.locale == 'ku' || $i18n.locale == 'ar'
                  ? ['kurd', 'subText-' + i.id]
                  : ['eng', 'subText-' + i.id]
              "
              v-if="i && i['name' + getLang()]"
              >{{ i["name" + getLang()] }}</span
            >
          </div>
        </carousel>

        <!-- /carousel -->
      </div>

      <!-- This is the Items -->
      <div class="bg_gray" :style="'background-color: ' + mainBackColor">
        <div class="container margin_60_40">
          <div
            :dir="$i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-flex mb-4 mr-4 justify-content-between"
            style="font-size: 25px; margin-top: -50px; justify-content: end"
          >
            <div>
              <h6
                :style="'color: ' + mainForeColor"
                :class="
                  $i18n.locale == 'ku' || $i18n.locale == 'ar'
                    ? 'mr-1 kurd'
                    : 'ml-3'
                "
                class="mt-3"
                v-if="theItemName"
              >
                {{ theItemName }}
              </h6>
            </div>
            <div
              :style="'color: ' + mainForeColor"
              class="viewBtns"
              :class="
                $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'ml-4' : ''
              "
            >
              <i class="listBtn bi bi-list-ul" @click="doList(1)"></i>
              <i
                class="imageBtn bi bi-image"
                :class="
                  $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'mr-2' : 'ml-2'
                "
                @click="doList(2)"
              ></i>

              <i
                @click="doList(3)"
                class="gridBtn bi bi-grid"
                :class="
                  $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'mr-2' : 'ml-2'
                "
              ></i>
            </div>
          </div>
          <div class="menuGrid">
            <h5
              class="text-center"
              :class="
                $i18n.locale == 'ku' || $i18n.locale == 'ar'
                  ? ['kurd']
                  : ['eng']
              "
              v-if="noItem"
            >
              {{ $t("noItem") }}
            </h5>

            <div
              class="item"
              v-for="i in filteredItems"
              :key="i.id"
              :style="'background-color:' + itemBackgrundColor"
            >
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                @click="modalData = i"
              ></button>
              <!-- <div style="width:100px; height:100px; background-color:black"></div> -->
              <img
                style="margin: 0; padding: 0"
                :src="$hostname + i.imageLink.replaceAll('\\', '/')"
                alt=""
              />

              <div
                class="info"
                :class="
                  $i18n.locale == 'ku' || $i18n.locale == 'ar' ? 'rtl' : 'ltr'
                "
              >
                <h3
                  :style="'color:' + itemTitleColor"
                  v-if="i && i['name' + getLang()]"
                >
                  {{ i["name" + getLang()] }}
                </h3>
                <!-- <span>Lettece, Chiken, Tomato, Cheese</span> -->

                <div class="desc">
                  <strong :style="'color:' + itemPriceColor"
                    >{{ $t("price") }}: {{ i.price }}
                    {{ $t("currency") }}</strong
                  >
                  <!-- <span class="discount">30%</span> -->
                </div>
              </div>
            </div>
          </div>

          <!-- /banner -->
        </div>
      </div>
      <!-- /bg_gray -->

      <!-- /shape_element_2 -->
    </main>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import carousel from "vue-owl-carousel";
import $ from "jquery";

export default {
  name: "App",
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      console.log("locale change", newVal, oldVal);

      this.theItemName = "";
    },
  },
  components: { carousel },
  data() {
    return {
      isIpad: false,
      //#e54750
      //chocolate
      addOnValue: null,
      isAddOnSelected: false,

      colors: null,
      mainBackColor: "#FFFFFF",
      mainForeColor: "#000000",
      btnColor: "#e54750",
      btnForeColor: "#000000",
      itemBackgrundColor: "#ffffff",
      itemTitleColor: "#000000",
      itemPriceColor: "#d2691e",

      isDelivery: true,

      myCurrentId: null,
      myDataBody: {
        mAC: null,
        RestaurantName: "parktower.mokairaq.com",
        Date: new Date(),
      },

      isClicked: false,
      Dads: null,
      Categories: null,
      subCategories: null,
      filteredSubCategories: null,
      subCategoryKey: "sub",

      items: null,
      filteredItems: null,
      modalData: null,

      noItem: false,
      noSubCategory: false,

      showSlider: false,
      sliderItems: null,

      cardObj: {
        itemId: null,
        name: null,
        nameKu: null,
        nameAr: null,
        nameTu: null,
        price: null,
        imageLink: null,
        quantity: 1,
        menuOrderSubAddOns: [],
      },

      menuOrderSubAddOn: {
        itemId: 0,
        name: null,
        nameKu: null,
        nameAr: null,
        nameTu: null,
        price: null,
        menuOrderSubId: 0,
      },

      routeTableNumber: null,
      contact: null,

      theItemName: null,

      selectedAddOns: [],
      testAddOn: null,
    };
  },

  methods: {
    getLang() {
      if (this.$i18n.locale == "en") {
        return "En";
      } else if (this.$i18n.locale == "ku") {
        return "Ku";
      } else if (this.$i18n.locale == "ar") {
        return "Ar";
      } else if (this.$i18n.locale == "tu") {
        return "Tu";
      }
    },
    reRenderSubCategory() {
      this.subCategoryKey += "subLoad";
      this.$nextTick().then(() => {
        // Add the component back in
        this.subCategoryKey += this.subCategoryKey;
      });
    },
    getDads() {
      this.$loading(true);
      this.$Axios
        .get(`${this.$hostname}/getFrontDads`)
        .then((Response) => {
          this.Dads = Response.data;

          setTimeout(() => {
            this.selectDadIndex(this.Dads[0] ? this.Dads[0].id : -12);
          }, 1000);

          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },

    getCategories() {
      this.$Axios
        .get(`${this.$hostname}/getFrontCategories`)

        .then((Response) => {
          this.subCategories = Response.data;

          this.filterSubCategories(
            this.subCategories[0] ? this.subCategories[0].dadId : -12
          );
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },

    getItems() {
      this.$loading(true);
      this.$Axios
        .get(`${this.$hostname}/getFrontItems`)
        .then((Response) => {
          this.items = Response.data;

          this.filterItem(
            this.items[0] ? this.items[0].categoryId : -12,
            this.filteredSubCategories[0]
              ? this.filteredSubCategories[0]["name" + this.getLang()]
              : ""
          );
          setTimeout(() => {
            this.selectSubCategoryIndex(
              this.items[0] ? this.items[0].categoryId : -12
            );

            this.proceedDoList();
          }, 300);

          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },

    filterSubCategories(id) {
      if (id == 12673) {
        this.$loading(true);
        //console.log("all");
        this.filteredSubCategories = this.subCategories;

        setTimeout(() => {
          this.$loading(false);
          this.reRenderSubCategory();
        }, 350);
      } else {
        this.$loading(true);

        if (this.showSlider) {
          window.scrollTo(200, 200);
        }

        this.filteredSubCategories = this.subCategories;
        this.filteredSubCategories = this.filteredSubCategories.filter(
          (item) => {
            return item.dadId == id;
          }
        );
        this.filterItem(
          this.filteredSubCategories[0]
            ? this.filteredSubCategories[0].id
            : -12,
          this.filteredSubCategories[0]
            ? this.filteredSubCategories[0]["name" + this.getLang()]
            : ""
        );

        setTimeout(() => {
          this.$loading(false);
          this.reRenderSubCategory();
          this.proceedDoList();
        }, 350);
      }
    },

    filterItem(id, name) {
      this.theItemName = name;
      if (id == 0) {
        this.$loading(true);
        //console.log("all");
        this.filteredItems = this.items;
        setTimeout(() => {
          this.$loading(false);
        }, 350);
      } else {
        this.$loading(true);

        if (this.showSlider) {
          window.scrollTo(600, 600);
        }

        this.filteredItems = this.items;
        this.filteredItems = this.filteredItems.filter((item) => {
          return item.categoryId == id;
        });

        this.proceedDoList();

        setTimeout(() => {
          this.$loading(false);
        }, 350);

        if (this.filteredItems.length == 0) {
          this.noItem = true;
        } else {
          this.noItem = false;
        }
      }
    },

    // doSelect(event) {

    //   let elems = document.querySelectorAll(".item_version_2 a img");
    //   elems.forEach((item) => {
    //     item.classList.remove("subactive");
    //   });
    //   event.target.classList.add("subactive");
    // },

    selectDadIndex(index) {
      let passedIndex = index;
      this.Dads.forEach((element) => {
        if (passedIndex == element.id) {
          $(".dad-" + passedIndex).css(
            "border-bottom",
            "solid " + this.btnColor
          );
        } else {
          $(".dad-" + element.id).css("border-bottom", "none");
        }
      });
    },

    selectSubCategoryIndex(index) {
      //console.log(index)

      let passedIndex = index;
      this.subCategories.forEach((element) => {
        if (passedIndex == element.id) {
          $(".subCat-" + passedIndex).css(
            "border",
            "solid " + this.btnColor + " 4px"
          );
        } else {
          $(".subCat-" + element.id).css("border", "none");
        }
      });
    },

    getMyId() {
      this.$Axios
        .get(`https://demo.pedigitalmenu.com/getMyId`)
        .then((Response) => {
          this.myCurrentId = Response.data.myCurrentId;
          // console.log("this is the number" + this.myCurrentId)
          localStorage.setItem("currentId", this.myCurrentId);
          this.saveMyData(this.myCurrentId);
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
        });
    },

    saveMyData(theMac) {
      this.myDataBody.mAC = theMac;
      this.$Axios
        .post(`https://demo.pedigitalmenu.com/CollectData`, this.myDataBody)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          //this.$loading(false);
        });
    },

    getMySetting() {
      this.$Axios
        .get(`${this.$hostname}/getLangSettings`)
        .then((Response) => {
          this.languages = Response.data;

          if (this.languages.sliderImage) {
            this.showSlider = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMyColors() {
      this.$Axios
        .get(`${this.$hostname}/getColors`)
        .then((Response) => {
          this.colors = Response.data;

          this.btnColor = this.colors.buttonColor
            ? this.colors.buttonColor
            : "#e54750";

          this.btnForeColor = this.colors.buttonForeColor
            ? this.colors.buttonForeColor
            : "#000000";

          this.mainBackColor = this.colors.mainBackgroundColor
            ? this.colors.mainBackgroundColor
            : "#FFFFFF";

          this.mainForeColor = this.colors.mainForeColor
            ? this.colors.mainForeColor
            : "#000000";

          this.itemBackgrundColor = this.colors.cardBackgroundColor
            ? this.colors.cardBackgroundColor
            : "#ffffff";
          this.itemTitleColor = this.colors.cardTitleColor
            ? this.colors.cardTitleColor
            : "#000000";
          this.itemPriceColor = this.colors.cardPriceColor
            ? this.colors.cardPriceColor
            : "#d2691e";

          //$(".owl-dots span").css("border", "2px solid " + this.btnColor);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMySlider() {
      this.$Axios
        .get(`${this.$hostname}/getSliderFrontImages`)
        .then((Response) => {
          this.sliderItems = Response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addToCart(x) {
      this.cardObj = {
        itemId: null,
        name: null,
        nameKu: null,
        nameAr: null,
        nameTu: null,
        price: null,
        imageLink: null,
        quantity: 1,
        menuOrderSubAddOns: [],
      };

      let addOnObj = {
        name: null,
        nameKu: null,
        nameAr: null,
        nameTu: null,
        price: 0,
        menuOrderSubId: 0,
      };
      let extraPrice = 0;
      this.selectedAddOns.forEach((element) => {
        if (x.id == element.itemId) {
          addOnObj.name = element.name;
          addOnObj.nameKu = element.nameKu;
          addOnObj.nameAr = element.nameAr;
          addOnObj.nameTu = element.nameTu;
          addOnObj.price = element.price;

          extraPrice += addOnObj.price;

          this.cardObj.menuOrderSubAddOns.push(addOnObj);
          addOnObj = {
            name: null,
            nameKu: null,
            nameAr: null,
            nameTu: null,
            price: 0,
            menuOrderSubId: 0,
          };
        }
      });

      // console.log("addOn Price " + extraPrice);

      // console.log("these are the addOns");
      // console.log(this.cardObj.menuOrderSubAddOns);

      this.cardObj.itemId = x.id;
      this.cardObj.name = x.nameEn;
      this.cardObj.nameKu = x.nameKu;
      this.cardObj.nameAr = x.nameAr;
      this.cardObj.nameTu = x.nameTu;
      this.cardObj.price = x.price + extraPrice;
      this.cardObj.imageLink = x.imageLink;

      this.$store.commit("addToCard", this.cardObj);

      this.$vToastify.setSettings({
        successDuration: 500,
        position: "bottom-right",
        theme: "dark",
      });

      var feedback = "Item added";

      if (this.$i18n.locale == "ku") {
        feedback = "زیادكرا";
      } else if (this.$i18n.locale == "ar") {
        feedback = "تم إضافة";
      } else if (this.$i18n.locale == "tu") {
        feedback = "öğe eklendi";
      }

      this.selectedAddOns = [];
      $(".myInputCheck").prop("checked", false);

      this.$vToastify.success({ title: feedback });
    },

    selectAddOn(itemId, theAddOn) {
      theAddOn.checked = !theAddOn.checked;

      let found = this.selectedAddOns.find(
        (prodct) => prodct.name == theAddOn.nameEn
      );

      if (!found) {
        this.menuOrderSubAddOn.itemId = itemId;
        this.menuOrderSubAddOn.name = theAddOn.nameEn;
        this.menuOrderSubAddOn.nameKu = theAddOn.nameKu;
        this.menuOrderSubAddOn.nameAr = theAddOn.nameAr;
        this.menuOrderSubAddOn.nameTu = theAddOn.nameTu;
        this.menuOrderSubAddOn.price = theAddOn.price;

        this.selectedAddOns.push(this.menuOrderSubAddOn);
      } else {
        if (!theAddOn.checked) {
          let theIndex = this.selectedAddOns.findIndex(
            (prodct) => prodct.name == theAddOn.nameEn
          );

          this.selectedAddOns.splice(theIndex, 1);
        }
      }

      theAddOn.checked = false;

      this.menuOrderSubAddOn = {
        itemId: 0,
        name: null,
        price: null,
        menuOrderSubId: 0,
      };
    },

    addQuantity(x) {
      this.cardObj = {
        itemId: null,
        name: null,
        nameKu: null,
        nameAr: null,
        nameTu: null,
        price: null,
        imageLink: null,
        quantity: 1,
      };
      this.cardObj.itemId = x.itemId;
      this.cardObj.name = x.name;
      this.cardObj.nameKu = x.nameKu;
      this.cardObj.nameAr = x.nameAr;
      this.cardObj.nameTu = x.nameTu;
      this.cardObj.price = x.price;
      this.cardObj.imageLink = x.imageLink;
      this.$store.commit("addQuantity", this.cardObj);
    },
    reduceQuantity(x) {
      this.cardObj = {
        itemId: null,
        name: null,
        nameKu: null,
        nameAr: null,
        nameTu: null,
        price: null,
        imageLink: null,
        quantity: 1,
      };
      this.cardObj.itemId = x.itemId;
      this.cardObj.name = x.name;
      this.cardObj.nameKu = x.nameKu;
      this.cardObj.nameAr = x.nameAr;
      this.cardObj.nameTu = x.nameTu;
      this.cardObj.price = x.price;
      this.cardObj.imageLink = x.imageLink;
      this.$store.commit("reduceQuantity", this.cardObj);
    },
    getMyQuantity(x) {
      let found = this.$store.state.cart.find((prodct) => prodct.itemId == x);
      if (found) {
        return found.quantity;
      }
    },
    doList(x) {
      if (x == 1) {
        //list view
        $(".imageBtn").css("color", this.mainForeColor);
        $(".gridBtn").css("color", this.mainForeColor);
        $(".menuGrid").css("grid-template-columns", "repeat(auto-fit, 310px)");

        $(".menuGrid").css("padding-right", "0");
        $(".menuGrid").css("padding-left", "0");

        $(".item").css("grid-template-columns", "1fr 2fr");
        $(".item").css("height", "90px");
        $(".item .info").css("display", "block");
        $(".item .info .desc").css("margin-top", "15px");
        localStorage.setItem("listMode", 1);

        $(".listBtn").css("color", this.btnColor);
      } else if (x == 2) {
        //single item
        $(".imageBtn").css("color", this.btnColor);
        $(".menuGrid").css("grid-template-columns", "repeat(auto-fit, 310px)");

        $(".menuGrid").css("padding-right", "0");
        $(".menuGrid").css("padding-left", "0");

        $(".item").css("grid-template-columns", "1fr");
        $(".item").css("height", "100%");
        $(".item .info").css("display", "flex");
        $(".item .info .desc").css("margin-top", "0");
        $(".listBtn").css("color", this.mainForeColor);
        $(".gridBtn").css("color", this.mainForeColor);
        localStorage.setItem("listMode", 2);
      } else if (x == 3) {
        //Grid view

        $(".gridBtn").css("color", this.btnColor);

        $(".menuGrid").css("grid-template-columns", "1fr 1fr");

        $(".menuGrid").css("padding-right", "13px");
        $(".menuGrid").css("padding-left", "13px");

        $(".item").css("grid-template-columns", "1fr");
        $(".item").css("width", "100%");
        $(".item").css("height", "100%");
        $(".item .info").css("display", "block");
        $(".item .info").css("text-align", "center");
        $(".item h3").css("margin-bottom", "5px");
        $(".item .info .desc").css("margin-top", "0");

        $(".listBtn").css("color", this.mainForeColor);
        $(".imageBtn").css("color", this.mainForeColor);
        localStorage.setItem("listMode", 3);
      }
    },

    proceedDoList() {
      if (!localStorage.getItem("listMode")) {
        localStorage.setItem("listMode", 2);
      } else {
        let listMode = parseInt(localStorage.getItem("listMode"));

        if (listMode == 1) {
          this.doList(1);
        } else if (listMode == 2) {
          this.doList(2);
        } else if (listMode == 3) {
          this.doList(3);
        }
      }
    },
  },
  mounted() {
    var width = screen.width;
    

    //check if ipad
    if (width >= 700) {
      this.isIpad = true;
    } else {
      this.isIpad = false;
    }


    setTimeout(() => {
      $(".myMain").click(function () {
        $(".dropdown").removeClass("open");
      });

      $(window).bind("mousewheel", function (event) {
        if (event.originalEvent.wheelDelta >= 0) {
          $(".dropdown").removeClass("open");
        } else {
          $(".dropdown").removeClass("open");
        }
      });
    }, 300);

    this.routeTableNumber = parseInt(
      new URLSearchParams(window.location.search).get("Table")
    );

    //this.routeTableNumber = this.$route.params.id

    //console.log(this.routeTableNumber);

    if (!localStorage.getItem("currentId")) {
      // alert("No Id available")
      this.getMyId();
    } else {
      // alert("Data haya")
      // console.log(localStorage.getItem("currentId"));
      this.saveMyData(localStorage.getItem("currentId"));
    }

    this.getDads();
    this.getCategories();
    this.getItems();
    this.getMySetting();
    this.getMySlider();
    this.getMyColors();
  },
};
</script>

<style>
</style>